import React, {useEffect, useState} from 'react';
import "./all.scss";
import Layout from "./layout/layout";
import Banner from "./home/banner";
import Servicesboxes from "./home/servicesboxes";
import Awardsrecognitions from "./home/awardsrecognitions";
import Serviceindustries from "./home/serviceindustries";
import Casestudies from "./home/casestudies";
import Tekrevoljourney from "./home/tekrevoljourney";
import Expertise from "./home/expertise";
import Insights from "./home/insights";
import Featured from "./home/featured";
import Partner from "./home/partner";
import Seo from "./layout/seo";
import axios from "axios";
import {baseURL} from "../utils/constant";
import {checkData} from "../helper";
import Loader from "./components/Loader";


// markup
const IndexPage = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/home-page`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };

    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);


    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Banner data={userData}/>
                    <Servicesboxes data={userData}/>
                    <Awardsrecognitions/>
                    <Serviceindustries data={userData}/>
                    <Casestudies data={userData}/>
                    <Tekrevoljourney data={userData}/>
                    <Expertise data={userData}/>
                    <Insights/>
                    <Featured/>
                    <Partner/>
                </>
            }
        </Layout>
    )
}

export default IndexPage

import React, {useState, useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import axios from "axios";
import {baseURL} from "../../utils/constant";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3001},
        items: 6
    },
    desktop: {
        breakpoint: {max: 3000, min: 1400},
        items: 6
    },
    tabletlandscape: {
        breakpoint: {max: 1399, min: 1200},
        items: 6
    },
    tablet: {
        breakpoint: {max: 1199, min: 667},
        items: 3
    },
    mobile: {
        breakpoint: {max: 666, min: 0},
        items: 1.4
    }
};

function Featured(props) {
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        const response = await axios.get(`${baseURL}/all-featured`);
        setUserData(response.data.data);

    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);


    return (

        <>

            <div className="tekrevol_featured p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_featured_title">
                            <div className="example center text-center" data-text="Featured">
                                <h2>We’re Featured In</h2>
                            </div>
                            <Carousel responsive={responsive} className="carousel_featured">
                                {userData?.map((el, i) => (
                                    <div className="featured_item">
                                        <div className="featured_img">
                                            <img src={el.image.path} className="img-fluid"
                                                 alt={el.name}/>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Featured;
import React from "react"
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Serviceindustries({data}) {
    return (
        <div className="p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div className="example" data-text="ROI-Centric">
                            <h2>{HtmlParser(data?.about_title)}</h2>
                        </div>
                        <div>{HtmlParser(data?.about_description)}</div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto text-center">
                        <img src={`${mediaBaseURL}${data?.about_img}`}
                             alt={data?.about_title}
                             className="img-fluid"/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Serviceindustries;
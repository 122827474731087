import React from 'react';
import Link from 'gatsby-link';
import {Col, Container, Row} from "react-bootstrap";
import {Startups, Enterprise, SMB} from "../../app-constants";

function Servicesboxes({data}) {
    return (
        <div className="tekrevol_ser_box">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_ser_container">
                        <div className="tekrevol_boxes">
                            {data?.services_tabs?.map((el, key) => {
                                switch (key) {
                                    case 0:
                                        return (
                                            <div className="box_item" key={key}>
                                                <h4>
                                                    {Startups}
                                                    {el.service_name}
                                                </h4>
                                                <ul>
                                                    {el.services_list.map((value, i) => (
                                                        <li><Link
                                                            to={`/services${value.item_url}`}>{value.item_name}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                        break;
                                    case 1:
                                        return (
                                            <div className="box_item" key={key}>
                                                <h4>
                                                    {Enterprise}
                                                    {el.service_name}
                                                </h4>
                                                <ul>
                                                    {el.services_list.map((value, i) => (
                                                        <li><Link
                                                            to={`/services${value.item_url}`}>{value.item_name}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                        break;
                                    case 2:
                                        return (
                                            <div className="box_item" key={key}>
                                                <h4>
                                                    {SMB}
                                                    {el.service_name}
                                                </h4>
                                                <ul>
                                                    {el.services_list.map((value, i) => (
                                                        <li><Link
                                                            to={`/services${value.item_url}`}>{value.item_name}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                        break;
                                }

                            })}

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Servicesboxes;
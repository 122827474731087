import React from 'react';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css'
import axios from "axios";
import {baseURL} from "../../utils/constant";

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 8000, min: 1601},
        items: 6
    },
    desktop: {
        breakpoint: {max: 1600, min: 1400},
        items: 5
    },
    landscape: {
        breakpoint: {max: 1399, min: 1200},
        items: 5
    },
    tablet: {
        breakpoint: {max: 1199, min: 900},
        items: 3.5
    },
    mobilelg: {
        breakpoint: {max: 899, min: 667},
        items: 2.5
    },
    mobile: {
        breakpoint: {max: 666, min: 0},
        items: 1
    }
};
const {useRef, useState, useEffect} = React;


const LazyImage = ({src, alt, title, text, content}) => {
    const rootRef = useRef();
    const [isVisible, setIsVisible] = useState(false);
    const loading = 'https://www.tekrevol.com/Tek-Spinner.gif';

    useEffect(() => {
        const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
        };

        const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        };

        if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
        }
    }, [isVisible]);
    return (
        <div className={content ? 'awards_item ' : 'awards_item small'}>
            <div className="awards_img">
                <img src={`https://tekrevol.tekstaging.com/storage/app/media${isVisible ? src : loading}`} ref={rootRef}
                     className="img-fluid" alt={alt}/>
                <h5>{title}</h5>
                <p>{text}</p>
            </div>
        </div>
    );
};

function Awads(props) {
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        const response = await axios.get(`${baseURL}/awards`);
        setUserData(response.data.data);

    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);
    return (
        <>
            <Carousel responsive={responsive} arrows={false}>
                {userData && userData ? userData.map((el, i) => (
                    <div key={i}>
                        <LazyImage
                            alt={el.name}
                            src={el.image} title={el.name} text={el.description} key={i}

                            content={props.content}
                        />

                    </div>
                )) : ''}
            </Carousel>

        </>
    );

}

export default Awads;
import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import TeeMatesProject from "../casestudies/TeeMatesProject";
import PizzaProject from "../casestudies/PizzaProject";
import Link from 'gatsby-link';
import {checkData} from "../../helper";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";


function Casestudies({data}) {
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        const response = await axios.get(`${baseURL}/case-study?featured=1&status=1&limit=4`);
        setUserData(response.data.data);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);

    return (
        <div className="tekrevol_casestudies tekrevol_gray_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="casestudies_section">
                        <div className="example" data-text="Our Portfolio">
                            <h2>{HtmlParser(checkData(data, 'casestudy_title'))}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'casestudy_description'))}</div>
                    </Col>
                </Row>
            </Container>
            <div className="tekrevol_project">
                {userData?.map((item, i) => {
                        if ((i + 1) % 2 === 0) {
                            return (
                                <PizzaProject data={item}/>
                            )
                        } else {
                            return (
                                <TeeMatesProject data={item}/>
                            )
                        }
                    }
                )}


                <div className="btn_load_all">
                    <Link to="">
                        <svg viewBox="0 0 21 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989592 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z"
                                fill="#F47A1F"/>
                        </svg>
                    </Link>
                </div>
            </div>
        </div>

    );
}

export default Casestudies;
import React, {useEffect, useRef} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from 'gatsby-link';
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";

function PizzaProject({data}) {
    // const imageRef = useRef(null)
    // useEffect(() => {
    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach((entry) => {
    //             if (entry.intersectionRatio > 0) {
    //                 const img = entry.target
    //                 img.src = img.dataset.src
    //                 observer.unobserve(img)
    //             }
    //         })
    //     })
    //     observer.observe(imageRef.current)
    //
    //     return () => observer.disconnect()
    // }, [])
    return (
        <>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_project_item">
                        <div className="pizza_project" style={{background: `${checkData(data, 'bg_light_color')}`}}>
                            <Row>
                                <Col xs={12} md={8} lg={6} xl={6} xxl={6}>
                                    <div className="project_info">
                                        <div className="logo_project">
                                            <img
                                                src={`${mediaBaseURL}${checkData(data, 'logo')}`}
                                                alt={checkData(data, 'name')} className="img-fluid"/>
                                        </div>
                                        <div className="framework_project">
                                            <ul style={{color: `${checkData(data, 'values_color')}`}}>
                                                {data && data.frameworks ? data.frameworks.map((value, i) => (
                                                    <li>{value.frameworks}</li>
                                                )) : null}
                                            </ul>
                                        </div>
                                    </div>

                                </Col>
                                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                                    <div className="project_statistics"
                                         style={{color: `${checkData(data, 'values_color')}`}}>
                                        <div className="download">
                                            {checkData(data, 'downloads')}
                                            <span>Downloads</span>
                                        </div>
                                        <div className="reward">
                                            {checkData(data, 'reward')}
                                            <span> Awards</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={6} xl={5} xxl={5}>
                                    <div className="project_title" style={{color: `${checkData(data, 'txt_color')}`}}>
                                        <h2 style={{color: `${checkData(data, 'txt_color')}`}}>{checkData(data, 'subtitle')}</h2>
                                        <div
                                            dangerouslySetInnerHTML={{__html: checkData(data, 'description')}}
                                            className="project_details"/>

                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={6} xl={7} xxl={7}>
                                    <div className="img_prev">
                                        {data?.project_images?.map((el, i) => (
                                            <div className="case_item">
                                                <img
                                                    src={`${mediaBaseURL}${checkData(el, 'image')}`}
                                                    alt={checkData(el, 'image')}
                                                    className="img-fluid"/>
                                            </div>

                                        ))}
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col xs={12} md={9} lg={7} xl={6} xxl={6} className="m-auto tekrevol_project_review">
                        <div className="project_review">
                            <p>“{checkData(data, 'project_review')}"
                                <svg width="218" height="171" viewBox="0 0 218 171" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.482788 0.492676H94.9834V95.0883L57.0882 170.879H9.98034L47.6856 95.0883H0.482788V0.492676Z"
                                        fill="#E2E2E2"/>
                                    <path
                                        d="M122.542 0.492676H217.042V95.0883L179.147 170.879H132.039L169.744 95.0883H122.542V0.492676Z"
                                        fill="#E2E2E2"/>
                                </svg>

                            </p>
                            <div className="review_info">
                                <div className="preson">
                                    <img
                                        src={`${mediaBaseURL}${checkData(data, 'person_image')}`}
                                        alt={checkData(data, 'person_name')} className="img-fluid"/>
                                </div>
                                <div className="preson_info">
                                    <h6>{checkData(data, 'person_name')}</h6>
                                    <div className="designation">
                                        {checkData(data, 'person_designation')}
                                    </div>
                                    <div className="project_link">
                                        <Link to={`/profile/${checkData(data, 'slug')}`}> Full Case Study
                                            <svg viewBox="0 0 21 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989592 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z"
                                                    fill="#F47A1F"/>
                                            </svg></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
        ;
}

export default PizzaProject;
import React from 'react';
import {Col, Container, Row, Nav, Tab, Accordion} from "react-bootstrap";
import Link from 'gatsby-link';
import HtmlParser from "react-html-parser";


function Expertise({data}) {

    return (
        <div className="tekrevol_expertise tekrevol_gray_bg  p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="example" data-text="Our Expertise">
                            <h2>{HtmlParser(data?.expertise_title)}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_expertise_tab dekstop_version">
                        <Tab.Container id="" defaultActiveKey="Healthcare">
                            <Row>
                                <Col xs={12} md={5} lg={5} xl={5}>
                                    <Nav variant="pills" className="flex-column">
                                        {data?.expertise_content?.map((item, i) => (
                                            <Nav.Item>
                                                <Nav.Link eventKey={item.title}> {item.title}</Nav.Link>
                                            </Nav.Item>
                                        ))}


                                    </Nav>
                                </Col>
                                <Col xs={12} md={7} lg={7} xl={7}>
                                    <Tab.Content>
                                        {data?.expertise_content?.map((item, i) => (
                                            <Tab.Pane eventKey={item.title}>
                                                <h6> {HtmlParser(item?.subtitle)}</h6>
                                                <div className="tekrevol_journey_para">{HtmlParser(item?.content)}</div>
                                                <div className="btn_load_all">
                                                    <Link to={data?.url}> Discover industry details
                                                        <svg viewBox="0 0 21 12" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989592 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z"
                                                                fill="#F47A1F"/>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </Tab.Pane>
                                        ))}

                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_expertise_tab mobile_version">
                        <Accordion defaultActiveKey="0">
                            {data?.expertise_content?.map((item, i) => (
                                <Accordion.Item eventKey={i} key={i}>
                                    <Accordion.Header>{item.title}</Accordion.Header>
                                    <Accordion.Body>
                                        <h6> {HtmlParser(item?.subtitle)}</h6>
                                        <div className="tekrevol_journey_para">{HtmlParser(item?.content)}</div>
                                        <div className="btn_load_all">
                                            <Link to={data?.url}> Discover industry details
                                                <svg viewBox="0 0 21 12" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989592 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z"
                                                        fill="#F47A1F"/>
                                                </svg>
                                            </Link>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Expertise;
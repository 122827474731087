import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Awads from "./awads";
import {Star} from "../../app-constants";


function Awardsrecognitions(props) {
    let className = "tekrevol_awards p-100"
    if (props.bg) {
        className = "tekrevol_awards gery tekrevol_gray_bg p-100"
    }

    return (
        <div className={className}>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={8} lg={7} xl={7} xxl={7} className="tekrevol_awards_title mt-auto mb-auto">
                        <div className="example" data-text="Winning Awards">
                            <h2>Awards & Recognitions</h2>
                        </div>
                        <p>Thriving on accelerating the path to disruption and value creation in all directions has
                            enabled us to receive acknowledgment and recognition by leading ratings and review
                            platforms.</p>
                    </Col>
                    <Col xs={12} md={4} lg={5} xl={5} xxl={5} className="text-end mt-auto">
                        <div className="tekrevol_rating">
                            <span>49 reviews on clutch</span>
                            <div className="tekrevol_rating_numbers">4.9</div>
                            <div className="tekrevol_star">
                                {Star}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="mt-auto mb-auto">
                        <Awads content={true}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Awardsrecognitions;
import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Tekrevoljourney({data}) {
    return (
        <div className="tekrevol_journey p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text="App Developer">
                            <h2>{HtmlParser(checkData(data, 'journey_title'))}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={6} xxl={6} className="tekrevol_journey_para">
                        <div className="img_boxing">
                            <div>{HtmlParser(checkData(data, 'journey_description'))}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Tekrevoljourney;